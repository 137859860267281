import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import merge from 'lodash/merge';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

type TStyleObject = ReturnType<typeof definePartsStyle>;

const antiBaseStyle = (style: TStyleObject): TStyleObject => {
  return merge(
    {},
    {
      container: {},
      control: {
        padding: 0,
        borderRadius: 'sm',
        _checked: {
          _disabled: {},
          backgroundColor: 'gray.100',
        },
      },
      label: {
        ml: 2,
      },
    },
    style
  );
};

export const Checkbox = defineMultiStyleConfig({
  baseStyle: {
    label: {
      alignItems: 'baseline',
    },
    control: {
      boxSize: '2.5', //it needs to be updated
      padding: '2.5',
      border: '0.1', //it needs to be updated
      borderRadius: 'sm-md',
      boxShadow: 'none',
      _checked: {
        _focusVisible: {
          bg: 'blue.500',
          borderColor: 'blue.500', // while checked the icon color needs to be updated to white
        },
        _hover: {
          bg: 'blue.200',
          borderColor: 'blue.200',
        },
      },
      _disabled: {
        borderColor: 'grey.300',
        _checked: {
          bg: 'blue.200',
          color: 'blue.50',
        },
        _hover: {
          borderColor: 'grey.300',
        },
        _focusVisible: {
          borderColor: 'grey.300',
        },
      },
      _invalid: {
        borderColor: 'red.500',
      },
    },
  },
  variants: {
    'republic-primary': antiBaseStyle({
      control: {
        borderColor: 'black',
        border: '2px solid',
        boxSize: '16px',
        _checked: {
          borderColor: 'blue.500',
          backgroundColor: 'blue.500',
        },
        _disabled: {
          borderColor: 'gray.100',
          backgroundColor: 'gray.100',

          _checked: {
            borderColor: 'gray.100',
            backgroundColor: 'gray.100',
          },
        },
      },
    }),
    republic: antiBaseStyle({
      container: {
        display: 'flex',
        alignItems: 'flex-start',
        _hover: {
          '> span': {
            borderColor: 'brand.blue.34',
          },
          _disabled: {
            '> span': {
              borderColor: 'grey.200',
              bgColor: 'grey.50',
            },
          },
          _checked: {
            '> span:first-of-type': {
              borderColor: 'brand.blue.34',
              bgColor: 'brand.blue.34',
            },
            _active: {
              '> span:first-of-type': {
                borderColor: 'brand.blue.c6',
                bgColor: 'brand.blue.c6',
              },
            },
            _disabled: {
              '> span:first-of-type': {
                borderColor: 'brand.blue.8b',
                bgColor: 'brand.blue.8b',
              },
            },
          },
        },
      },
      control: {
        width: '18px',
        height: '18px',
        mt: '3px',
        bg: 'white',
        border: '1px solid',
        borderColor: 'grey.200',
        borderRadius: 'sm',
        _hover: {
          borderColor: 'brand.blue.34',
          _checked: {
            bgColor: 'brand.blue.34',
            borderColor: 'brand.blue.34',
            _active: {
              borderColor: 'brand.blue.c6',
              bgColor: 'brand.blue.c6',
            },
          },
          _active: {
            borderColor: 'brand.blue.c6',
          },
        },
        _checked: {
          bgColor: 'blue.500',
          borderColor: 'blue.500',
          _hover: {
            bgColor: 'brand.blue.34',
            borderColor: 'brand.blue.34',
          },
          _active: {
            bgColor: 'blue.800',
            borderColor: 'blue.800',
          },
          _disabled: {
            bgColor: 'brand.blue.8b',
            borderColor: 'brand.blue.8b',
            _checked: {
              bgColor: 'brand.blue.8b',
              borderColor: 'brand.blue.8b',
            },
          },
        },
        _disabled: {
          bgColor: 'grey.50',
          borderColor: 'grey.200',
          opacity: 1,
        },
      },
      label: {
        ml: 2,
        fontSize: 'md',
        lineHeight: 'base',
        fontWeight: '400',
        letterSpacing: 'less-tight',
        color: 'black',
        _disabled: {
          color: 'black',
          opacity: 1,
        },
      },
    }),
    'republic-outline': antiBaseStyle({
      container: {
        alignItems: 'flex-start',
      },
      control: {
        width: '20px',
        height: '20px',
        borderColor: 'grey.200',
        border: '1px',
        bg: 'white',
        mt: 0.5,
        _checked: {
          borderColor: 'blue.500',
          backgroundColor: 'blue.500',
        },
        _disabled: {
          borderColor: 'grey.50',
        },
      },
      label: {
        ml: 2,
      },
    }),
    'republic-offering': antiBaseStyle({
      control: {
        width: '18px',
        height: '18px',
        background: 'white',
        borderRadius: 'sm',
        opacity: 0.9,
        border: '1px solid',
        borderColor: 'grey.200',
        alignSelf: 'flex-start',
        _checked: {
          border: 'none',
          bgColor: 'blue.500',
          _disabled: {
            opacity: 1,
            borderColor: 'none',
            bgColor: 'blue.300',
            color: 'white',
          },
        },
        _disabled: {
          border: 'none',
        },
      },
      label: {
        ml: 2,
        _checked: {
          _disabled: {
            opacity: 1,
          },
        },
      },
    }),
  },
});
