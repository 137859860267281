export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  grey: {
    '50': '#F2F2F2',
    '100': '#E6E6E6',
    '200': '#CCCCCC',
    '300': '#B3B3B3',
    '400': '#999999',
    '500': '#808080',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  gray: {
    '50': '#F2F2F2',
    '100': '#E6E6E6',
    '200': '#CCCCCC',
    '300': '#B3B3B3',
    '400': '#999999',
    '500': '#808080',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  blue: {
    '50': '#E5EDFF',
    '100': '#CCDAFF',
    '200': '#99B6FF',
    '300': '#6691FF',
    '400': '#336DFF',
    '500': '#0049FF',
    '600': '#0042EB',
    '700': '#003DD6',
    '800': '#0037C2',
    '900': '#0031AD',
  },
  green: {
    '50': '#E0FFE4',
    '100': '#C2FFC9',
    '200': '#85FF93',
    '300': '#47FF5D',
    '400': '#0AFF27',
    '500': '#00CC18',
    '600': '#00BD16',
    '700': '#00AD14',
    '800': '#009E12',
    '900': '#008F11',
  },
  red: {
    '50': '#FFF0F0',
    '100': '#FEDCDC',
    '200': '#FDBABA',
    '300': '#FC9C9C',
    '400': '#FB7979',
    '500': '#FA5757',
    '600': '#F93434',
    '700': '#F81111',
    '800': '#DA0707',
    '900': '#B70505',
  },
  orange: {
    '50': '#FFF5EB',
    '100': '#FFEAD1',
    '200': '#FFD4A3',
    '300': '#FFBF75',
    '400': '#FFA947',
    '500': '#FF961A',
    '600': '#FF8800',
    '700': '#E67A00',
    '800': '#CC6D00',
    '900': '#B35F00',
  },
  gold: {
    '50': '#FDF9F2',
    '100': '#FBF3E4',
    '200': '#F6E6C5',
    '300': '#F2DAAB',
    '400': '#EECF90',
    '500': '#EAC273',
    '600': '#E5B452',
    '700': '#E0A52F',
    '800': '#C88F1E',
    '900': '#A97919',
  },
  purple: {
    '50': '#F4E4FB',
    '100': '#EBCDF9',
    '200': '#D597F2',
    '300': '#C165EB',
    '400': '#AB2FE4',
    '500': '#8B19C1',
    '600': '#8317B5',
    '700': '#7C16AC',
    '800': '#72149E',
    '900': '#6C1395',
  },
  pink: {
    '50': '#FEEBEF',
    '100': '#FED8DF',
    '200': '#FCB0BE',
    '300': '#FB899E',
    '400': '#F9617D',
    '500': '#F83A5C',
    '600': '#F71C45',
    '700': '#EC0832',
    '800': '#CF072C',
    '900': '#B10626',
  },
};
