export const Heading = {
  sizes: {
    'republic-h1': {
      fontSize: { base: '6xl', sm: '7xl' },
      lineHeight: { base: '50px', sm: '64px' },
      letterSpacing: 'tightest',
      fontWeight: 'semibold',
    },
    'republic-h2': {
      fontSize: { base: '4xl', sm: '5xl' },
      lineHeight: { base: '40px', sm: '48px' },
      letterSpacing: 'tighter',
      fontWeight: 'semibold',
    },
    'republic-h3': {
      fontSize: { base: '3xl', sm: '4xl' },
      lineHeight: { base: '34px', sm: '40px' },
      letterSpacing: { base: 'tight', sm: 'tighter' },
      fontWeight: 'semibold',
    },
    'republic-h4': {
      fontSize: '2xl',
      lineHeight: '30px',
      letterSpacing: 'tight',
      fontWeight: 'semibold',
    },
    'republic-h5': {
      fontSize: 'lg',
      lineHeight: '26px',
      letterSpacing: 'tight',
      fontWeight: 'bold',
    },
  },
};
