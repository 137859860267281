export const textStyles = {
  'body-xxl': {
    fontSize: ['3xl', '4xl'],
    lineHeight: ['9', '10'], // TODO: Need a clarification on the mobile line height
    letterSpacing: 'less-tight',
  },
  'body-xl': {
    fontSize: '2xl',
    lineHeight: '8',
    letterSpacing: 'tight',
  },
  'body-lg': {
    fontSize: 'lg',
    lineHeight: '6',
    letterSpacing: 'tight',
  },
  'body-md': {
    fontSize: 'md',
    lineHeight: '6',
    letterSpacing: 'less-tight',
  },
  'body-sm': {
    fontSize: 'sm',
    lineHeight: '4',
    letterSpacing: 'less-tight',
  },
  'body-xs': {
    fontSize: 'xs',
    lineHeight: '4',
  },
};
