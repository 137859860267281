export const letterSpacings = {
  tightest: '-0.04em',
  tighter: '-0.03em',
  tight: '-0.02em',
  'less-tight': '-0.01em',
  normal: '0',
  wide: '0.02em',
  wider: '0.03em',
  widest: '0.04em',
};
