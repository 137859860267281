const republicFont = `'Inter var', 'Helvetica Neue', Arial, sans-serif`;
const republicCode = `'SFMono-Regular', Roboto Mono, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace`;

export const fonts = {
  heading: republicFont,
  body: republicFont,
  mono: republicCode,
  // TODO: MK ROS-36 Find "code" font occurences in the codebase and replace with "mono"
};

export const letterSpacings = {
  tightest: '-0.04em',
  tighter: '-0.03em',
  tight: '-0.02em',
  'less-tight': '-0.01em',
  normal: '0',
  wide: '0.02em',
  wider: '0.03em',
  widest: '0.04em',
};

export const lineHeights = {
  normal: 'normal',
  none: 1,
  tinier: 1.125,
  tiny: 1.2,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: 2,
};

export const fontSizes = {
  xs: '0.625rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.75rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.25rem',
  '7xl': '4.25rem',
  '8xl': '6rem',
  '9xl': '8rem',
};

export const textStyles = {
  lead: {
    fontSize: '2xl',
    lineHeight: '30px',
    letterSpacing: 'tight',
  },
  'lg-dls': {
    fontSize: 'lg',
    lineHeight: '24px',
    letterSpacing: 'tight',
  },
  lg: {
    fontSize: 'lg',
    lineHeight: '26px',
    letterSpacing: 'tight',
  },
  md: {
    fontSize: 'md',
    lineHeight: 'base',
    letterSpacing: 'less-tight',
  },
  'md-bold': {
    fontSize: 'md',
    fontWeight: '700',
    lineHeight: 'base',
    letterSpacing: 'less-tight',
  },
  sm: {
    fontSize: 'sm',
    lineHeight: '16px',
    letterSpacing: 'less-tight',
  },
  xs: {
    fontSize: 'xs',
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
};
