export const lineHeights = {
  normal: 'normal',
  none: 1,
  tinier: 1.125,
  tiny: 1.2,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: 2,
};
