export const Text = {
  variants: {
    dashed: {
      textDecoration: 'dashed underline 0.75px',
      textUnderlineOffset: '2px',
    },
  },
  sizes: {
    // TODO - https://linear.app/republic/issue/FND-1111/introduce-textstyles-in-the-republic-app-r-client
    // Flag sizes and variants defined in this file as deprecated or
    // remove them and refactor existing code to use the new text styles
    'republic-lead': {
      fontSize: '2xl',
      lineHeight: '30px',
      letterSpacing: 'tight',
    },
    'republic-body-lg': {
      fontSize: 'lg',
      lineHeight: '26px',
      letterSpacing: 'tight',
    },
    'republic-body-md': {
      fontSize: 'md',
      lineHeight: 'base',
      letterSpacing: 'less-tight',
    },
    'republic-body-sm': {
      fontSize: 'sm',
      lineHeight: '16px',
      letterSpacing: 'less-tight',
    },
    'republic-body-xs': {
      fontSize: 'xs',
      lineHeight: '16px',
      letterSpacing: 'normal',
    },
  },
};
