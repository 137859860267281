import { ComponentStyleConfig } from '@chakra-ui/react';

export const Textarea: ComponentStyleConfig = {
  variants: {
    'republic-lite': {
      border: '1px solid',
      borderColor: 'grey.400',
      borderRadius: 'base',
      _placeholder: {
        color: 'grey.200',
        fontSize: 'md',
        lineHeight: 'base',
        fontWeight: 'normal',
        letterSpacing: 'less-tight',
      },
      color: 'grey.900',
      fontSize: 'md',
      lineHeight: 'base',
      fontWeight: 'normal',
      letterSpacing: 'less-tight',
      position: 'relative',
    },
  },
};
